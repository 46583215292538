<template>
  <v-container grid-list-md>
    <v-card>
      <list-header :title="listTitle" :groupable="false" :printable="true" :people="people" :groups="groupedPeople" @print="print"></list-header>
      <list v-if="view==='list'" :people="people" :group="group" :sortable="true" @reload="reload" ref="list"></list>
      <grouped v-else-if="isGrouped" :people="groupedPeople" :group="group" :view="view" ref="grouped"></grouped>
      <card v-else-if="view==='card'" :people="people" ref="card"></card>
      <photo v-else-if="view==='photo'" :people="people" ref="photo"></photo>
      <div v-else>{{ view }}</div>
    </v-card>
  </v-container>
</template>
<style>
  @media print {
    div.v-image__image {
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
    }
    div.row {
      display: block;
    }
    div.row > div {
      display: inline-block;
      flex: none;
    }
    div.row div {
      page-break-inside: avoid;
    }
  }
</style>
<script>
import { ref, reactive, computed, onMounted } from '@vue/composition-api'

export default {
  components: {
    ListHeader: () => import('@/components/greatscots/Header'),
    List: () => import('@/components/greatscots/List'),
    Grouped: () => import('@/components/greatscots/Grouped'),
    Card: () => import('@/components/greatscots/Card'),
    Photo: () => import('@/components/greatscots/Photo')
  },
  setup (props, { root, emit, refs }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const id = computed(() => root.$route.params['id'])
    const listTitle = ref('')
    const people = reactive([])
    const groupedPeople = reactive([])
    const group = ref('group')
    const isGrouped = computed(() => {
      return group.value !== '' && group.value != null && typeof (group.value) !== 'undefined'
    })

    onMounted(async () => {
      const { title, instructors, students } = await root.$feathers.service('calendar/classes').get(id.value)
      listTitle.value = title
      const { data: instr } = await root.$feathers.service('directory/people').find({ query: { pidm: { $in: instructors.map(({ pidm }) => pidm) }, $sort: { 'name.last': 1, 'name.first': 1 } } })
      groupedPeople.push({ group: 'Instructors', people: instr })
      instr.forEach((row) => people.push({ ...row, group: 'Instructors' }))
      const reg = students.filter(({ regStatus }) => ['RE', 'RW'].includes(regStatus)).map(({ pidm }) => pidm)
      const wl = students.filter(({ regStatus }) => regStatus === 'WL').map(({ pidm }) => pidm)
      const aggregate = [
        { $match: { pidm: { $in: reg } } },
        { $sort: { 'name.last': 1, 'name.first': 1 } }
      ]
      const { data: stu } = await root.$feathers.service('directory/people').find({ query: { aggregate } })
      if (!user.value.roles.includes('Employee')) {
        // The person accessing this list is not an employee; make sure they are in the students list and, if not, send them to the lists page
        if (stu.filter(({ pidm }) => pidm === user.value.pidm).length === 0) {
          root.$store.dispatch('main/snackbar', { color: 'error', text: 'You do not have access to view the requested class' })
          root.$router.replace('/greatscots/lists')
          return
        }
      }
      groupedPeople.push({ group: 'Registered Students', people: stu })
      stu.forEach((row) => people.push({ ...row, group: 'Registered Students' }))
      if (instr.filter(({ pidm }) => pidm === user.value.pidm).length > 0) {
        // Only instructors can see the wait-listed students
        const { data: wlStu } = await root.$feathers.service('directory/people').find({ query: { pidm: { $in: wl }, $sort: { 'name.last': 1, 'name.first': 1 } } })
        groupedPeople.push({ group: 'Registered Students', people: wlStu })
        wlStu.forEach((row) => people.push({ ...row, group: 'Wait-Listed Students' }))
      }
    })

    const view = computed(() => root.$store.state.greatscots.listView)

    const expandAllGroups = ref(false)
    function print (options) {
      const win = window.open()
      if (!win) {
        alert('It looks like you are blocking popups. To ensure good printing, we have to create a new window. Please enable popups and press the print button again.')
        return
      }
      win.addEventListener('load', () => { console.log('loaded'); win.print() }, true)
      win.document.write('<h1 style="text-align:center">' + listTitle.value + '</h1>')
      if (view.value === 'list') {
        refs.list.print(win, options)
      } else if (group.value !== '' && group.value != null && typeof (group.value) !== 'undefined') {
        refs.grouped.print(win)
      } else if (view.value === 'card') {
        refs.card.print(win)
      } else if (view.value === 'photo') {
        refs.photo.print(win)
      }
    }

    const reload = (args) => {
      emit('reload', args)
    }

    return {
      user,
      id,
      listTitle,
      people,
      groupedPeople,
      group,
      isGrouped,
      expandAllGroups,
      print,
      view,
      reload
    }
  }
}
</script>
